import { AfterViewInit, Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import * as Postmonger from 'postmonger';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, Subject } from 'rxjs';
import { Payload } from './model/payload';
import { SfmcService } from './service/sfmc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Token } from './model/token';

declare global {
  interface Window { customNamespace: any; }
}
window.customNamespace = window.customNamespace || {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('instance') instance: NgbTypeahead;

  selected: Payload = new Payload();
  tokens: Token[] = [];
  token: Token;

  errorMessage: string;
  registerForm: FormGroup;
  submitted: boolean;
  connection = new Postmonger.Session();

  dataDinamic: any[] = [];
  entryData: any[] = [];
  tokensData: any[] = [];

  focus$ = new Subject<any>();
  click$ = new Subject<any>();

  payload = {};
  hasInArguments = false;
  eventDefinitionKey = "";
  journeydata = {};
  authTokens = { fueltoken: "" };
  journeySchema = {};

  constructor(
    private sfmcService: SfmcService,
    private notifierService: NotifierService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private zone: NgZone
  ) {
    this.initializeNamespace();
  }
  
  private registerPostmongerEvents() {
    this.connection.on('requestedTriggerEventDefinition', this.requestedTriggerEventDefinition.bind(this));
    this.connection.on('initActivity', this.initialize.bind(this));
    this.connection.on('requestedTokens', this.onGetTokens.bind(this));
    this.connection.on('clickedNext', this.onClickedNext.bind(this));
    this.connection.on('requestedDataSources', this.requestDataSources.bind(this));
    this.connection.on('requestedSchema', this.requestedSchema.bind(this));
  }

  private initializeNamespace() {
    window.customNamespace.saveForm = (payload) => this.zone.run(() => this.save(payload));
    window.customNamespace.getLoadAttributeSets = (inArguments, journeyEntryDataChanged = false) => this.zone.run(() => this.LoadAttributeSets(inArguments, journeyEntryDataChanged));
    window.customNamespace.getRequetedSchema = () => this.zone.run(() => this.connection.trigger('requestSchema'));
    window.customNamespace.runInit = () => this.zone.run(() => this.connection.trigger('ready'));
    window.customNamespace.callJoinSelectedData = (tokensData) => this.zone.run(() => {
      this.tokensData = tokensData;
      this.joinSelectedData();
    });
  }

  ngAfterViewInit(): void {
    this.connection.trigger('requestTriggerEventDefinition');
  }

  ngOnInit(): void {
    this.initForm();
    this.registerPostmongerEvents();
  }

  get f() { return this.registerForm.controls; }

  addTagFn(text) {
    return text;
  }

  save(payload) {
    console.log("*** Saving Data ***")
    console.log("Selected data:")
    console.log(this.selected)
    this.submitted = true;

    if (this.registerForm.invalid) {
      this.notifierService.notify('error', 'Por favor complete los datos marcados en rojo.');
      return;
    }

    this.spinner.show();

    const inArgs = [];
    const selectedToken: string = this.selected.token.fullyQualifiedName;
    const token: string = this.selected.journeyEntryData ? `\{\{${selectedToken}\}\}` : `\{\{Contact.Attribute.${selectedToken}\}\}`;

    console.log(selectedToken)
    console.log(token)

    const arg: any = {
      token,
      journeyEntryData: this.selected.journeyEntryData,
      titulo: this.selected.titulo.join(' '),
      cuerpo: this.selected.cuerpo.join(' '),
      deepLink: this.selected.deepLink.join(' '),
      campaignId: this.selected.campaignId.join(''),
      subcampaignId: this.selected.subcampaignId.join(''),
    };

    console.log("Arguments:")
    console.log(arg)

    inArgs.push(arg);
    payload['arguments'].execute.inArguments = inArgs;
    payload['metaData'].isConfigured = true;

    this.connection.trigger('updateActivity', payload);
    this.submitted = false;
    this.spinner.hide();
    console.log("*** End Saving Data ***")
  }

  cambiaToken($event) {
    this.selected.token = $event.item;
  }

  journeyEntryDataChange($event) {
    this.selected.journeyEntryData = $event.currentTarget.checked;
    const inArguments = this.hasInArguments ? this.payload['arguments'].execute.inArguments : {};
    if(Array.isArray(inArguments) && inArguments.length > 0) 
      inArguments[0].journeyEntryData = $event.currentTarget.checked;
    window.customNamespace.getLoadAttributeSets(inArguments, true);
  }

  formatter = (x: { fullyQualifiedName: string }) => x.fullyQualifiedName;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.tokens
        : this.tokens.filter(v => v.fullyQualifiedName.toLowerCase().includes((typeof term === 'string' ? term.toLowerCase() : (term as HTMLInputElement).value?.toLowerCase())))))
    );
  }

  initialize(data) {
    console.log("*** Initialize Event ***")
    try {
      console.log(data)
      if (data) {
        this.payload = data;
      }

      this.hasInArguments = Boolean(
        this.payload['arguments'] &&
        this.payload['arguments'].execute &&
        this.payload['arguments'].execute.inArguments &&
        this.payload['arguments'].execute.inArguments.length > 0
      );

      const inArguments = this.hasInArguments ? this.payload['arguments'].execute.inArguments : {};
      window.customNamespace.getLoadAttributeSets(inArguments);
      console.log("*** End Initialize Event ***")
    } catch (error) {
      console.error('Initialize Error:', error);
    }
  }


  

  LoadAttributeSets(inArguments, journeyEntryDataChanged = false) {
    console.log("*** Loading Attributes ***")

    console.log(`journeyEntryDataChanged: ${journeyEntryDataChanged}`)
    console.log("Arguments:")
    console.log(inArguments)
    try {
      const isjourneyEntryData = this.selected.journeyEntryData || inArguments[0]?.journeyEntryData
      this.spinner.show();

      if(isjourneyEntryData) {
        window.customNamespace.getRequetedSchema();
        this.updateSelectedFromInArguments(inArguments, journeyEntryDataChanged);
        return;
      }

      this.sfmcService.getTokens('/sfmc/attributesetdefinitions/').subscribe((result) => {
        this.tokens = result.reduce((previous, current) => (previous.push(...current.attributes), previous), []);
        console.log("*** Tokens ***")
        console.log(this.tokens)
        this.tokensData = this.tokens.map(x => `{{Contact.Attribute.${x.fullyQualifiedName}}}`);
        console.log("*** TokensData ***")
        console.log(this.tokensData)
        this.joinSelectedData();
        this.updateSelectedFromInArguments(inArguments, journeyEntryDataChanged);

      });
    } catch (error) {
      console.error('LoadAttributeSets Error:', error);
    }
  }

  onGetTokens(tokens) {
    try {
      this.authTokens = tokens;
    } catch (error) {
      console.error('OnGetTokens Error:', error);
    }
  }

  onClickedNext() {
    try {
      window.customNamespace.saveForm(this.payload);
    } catch (error) {
      console.error('OnClickedNext Error:', error);
    }
  }

  requestDataSources(dataSources: any) {
    try {
      this.journeydata = dataSources;
    } catch (error) {
      console.error('RequestDataSources Error:', error);
    }
  }

  requestedSchema(data) {
    console.log("*** Requested Schema ***")
    this.tokens = data['schema'].map(x => ({
      "fullyQualifiedName": x.key
    }));
    this.tokensData = data['schema'].map(x => `{{${x.key}}}`);
    console.log(this.tokensData)
    window.customNamespace.callJoinSelectedData(this.tokensData);
    console.log("*** End Requested Schema ***")
  }

  joinSelectedData() {
    this.dataDinamic = [...(this.tokensData || [])];
    // add , ...(this.entryData || []) to include manually journey Data
  }

  requestedTriggerEventDefinition(eventDefinitionModel: any) {
    console.log("*** Requested Event Definition ***")
    try {
      this.eventDefinitionKey = eventDefinitionModel.eventDefinitionKey;
      window.customNamespace.eventDefinitionModel = eventDefinitionModel;
      console.log("*** End Requested Event Definition ***")
      window.customNamespace.runInit();
    } catch (error) {
      console.error('Requested Event Definition Error:', error);
    }
  }

  private initForm() {
    this.registerForm = this.formBuilder.group({
      titulo: ['', Validators.required],
      token: ['', Validators.required],
      cuerpo: ['', Validators.required],
      deepLink: ['', null],
      journeyEntryDataCheckbox: [false],
      campaignId: ['', null],
      subcampaignId: ['', null],
    });
  }

  private updateSelectedFromInArguments(inArguments: any[], journeyEntryDataChanged: boolean) {
    if (!Array.isArray(inArguments) || inArguments.length == 0) {
      this.spinner.hide();
      return;
    }
    
    const newPayload = new Payload();
    newPayload.journeyEntryData = inArguments[0].journeyEntryData;

    this.selected = newPayload

    const tokenString = this.selected.journeyEntryData ? "{{" : "{{Contact.Attribute.";
    const tokenEndString = "}}";

    inArguments[0].token = inArguments[0].token.replace(tokenString, "").replace(tokenEndString, "");

    if (journeyEntryDataChanged) {
      this.spinner.hide();
      return;
    }

    this.selected.titulo = inArguments[0].titulo.split(" ");
    console.log(`tokens list: ${this.tokens}`)
    this.selected.token = {"fullyQualifiedName": inArguments[0].token, "id": 0}
    this.selected.cuerpo = inArguments[0].cuerpo.split(" ");
    this.selected.deepLink = inArguments[0].deepLink.split(" ");
    this.selected.campaignId = [inArguments[0].campaignId];
    this.selected.subcampaignId = [inArguments[0].subcampaignId];
    this.spinner.hide();
  }
}