<notifier-container></notifier-container>

<form [formGroup]="registerForm">
  <div class="p-3">
    
    <!-- API Event Checkbox -->
    <div class="form-check form-switch fs-6" style="height: 20px;"> 
      <input 
        class="form-check-input" 
        type="checkbox" 
        role="switch" 
        id="journeyEntryDataCheckbox" 
        formControlName="journeyEntryDataCheckbox"
        [(ngModel)]="selected.journeyEntryData"
        (change)="journeyEntryDataChange($event)"
      >
      <label class="form-check-label" style="display: flex; gap: 5px;" for="journeyEntryDataCheckbox">Journey sin MaestroDeClientes <div style="position:relative">
        <button class="slds-button slds-button_icon slds-button slds-button_icon" aria-describedby="help" aria-disabled="true" title="Help">
          <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
          </svg>
          <span class="slds-assistive-text">Help</span>
        </button>
        <div class="slds-popover slds-popover_tooltip slds-nubbin_bottom-left" role="tooltip" id="help" style="position:absolute;top:-4px;left:20px;width:300px">
          <div class="slds-popover__body">Al activar esta funcionalidad, se podrán utilizar los campos de la Data Extension del Journey sin necesidad de conectarlo al MaestroDeClientes (También aplica para journeys con ApiEvent). *No se podrá acceder a campos de Data Extensions relacionadas*</div>
        </div>
      </div></label>
    </div>

    <div class="slds-card slds-theme_shade">
      <div class="slds-card__header slds-grid">
        <div class="slds-notify slds-notify_alert slds-alert_warning" role="alert">
          <span class="slds-assistive-text">warning</span>
          <span class="slds-icon_container slds-icon-utility-warning slds-m-right_x-small">
            <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>
            </svg>
          </span>
          <h2>Debes completar obligatoriamente los campos marcados con '*'</h2>
        </div>
      </div>
    </div>

    <!-- Id usuario muevo -->
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="token" class="col-form-label">* Id usuario muevo</label>
          <div class="input-group">
            <input 
              id="token"
              type="text"
              class="form-control"
              [(ngModel)]="selected.token"
              (focus)="focus$.next($event.target)"
              (click)="click$.next($event.target)"
              #instance="ngbTypeahead"
              [ngbTypeahead]="search"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (selectItem)="cambiaToken($event)"
              formControlName="token"
              [ngClass]="{ 'is-invalid': submitted && f.token.errors }"
            />
            <button 
              *ngIf="token" 
              type="button" 
              class="btn btn-link btn-icon mr-2 mb-2" 
              (click)="token=null"
            >
              <i class="icon ion-ios-close"></i>
            </button>
            <div *ngIf="submitted && f.token.errors" class="invalid-feedback">
              Este campo es obligatorio.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Titulo -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Titulo</label>
        <ng-select 
          id="titulo" 
          formControlName="titulo" 
          [ngClass]="{ 'is-invalid': submitted && f.titulo.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.titulo"
        >
        </ng-select>
        <div *ngIf="submitted && f.titulo.errors" class="invalid-feedback">
          Este campo es obligatorio.
        </div>
      </div>
    </div>

    <!-- Cuerpo -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Cuerpo</label>
        <ng-select 
          id="cuerpo" 
          formControlName="cuerpo" 
          [ngClass]="{ 'is-invalid': submitted && f.cuerpo.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.cuerpo"
        >
        </ng-select>
        <div *ngIf="submitted && f.cuerpo.errors" class="invalid-feedback">
          Este campo es obligatorio.
        </div>
      </div>
    </div>

    <!-- DeepLink -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">DeepLink</label>
        <ng-select 
          id="deepLink" 
          formControlName="deepLink" 
          [ngClass]="{ 'is-invalid': submitted && f.deepLink.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.deepLink"
        >
        </ng-select>
      </div>
    </div>

    <!-- Id de campaña -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Id de campaña</label>
        <ng-select 
          id="campaignId" 
          formControlName="campaignId" 
          [ngClass]="{ 'is-invalid': submitted && f.campaignId.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.campaignId"
        >
        </ng-select>
        <div *ngIf="submitted && f.campaignId.errors" class="invalid-feedback">
          Este campo es obligatorio.
        </div>
      </div>
    </div>

    <!-- Id de sub campaña -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Id de subcampaña</label>
        <ng-select 
          id="subcampaignId" 
          formControlName="subcampaignId" 
          [ngClass]="{ 'is-invalid': submitted && f.subcampaignId.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.subcampaignId"
        >
        </ng-select>
        <div *ngIf="submitted && f.subcampaignId.errors" class="invalid-feedback">
          Este campo es obligatorio.
        </div>
      </div>
    </div>    
  </div>
</form>

<ngx-spinner 
  bdColor="rgba(0, 0, 0, 0.8)" 
  size="medium" 
  color="#fff" 
  type="ball-beat" 
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>