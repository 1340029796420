import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { endpoints } from 'src/environments/environment';
import { HttpError } from '../model/httpError';

@Injectable({
  providedIn: 'root'
})
export class SfmcService {
  private urlTokens = endpoints.apiTokens;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      })
    };

  constructor(private httpError: HttpError,
              private httpClient: HttpClient) { }

  getTokens(route: string): Observable<any[]> {
    return this.httpClient.post<any[]>(this.urlTokens + route, this.httpOptions)
      .pipe(
        catchError(this.httpError.handleError)
      );
  }

  get(route: string): Observable<any> {
    return this.httpClient.get<any>(this.urlTokens + route, this.httpOptions)
      .pipe(
        catchError(this.httpError.handleError)
      );
  }
}
